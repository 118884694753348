import React, { useState } from 'react'
import { PLAY_RATES } from '../../configs'
import { usePlayerContext, useVideoDataContext } from '../../hooks'

import { Button, Dropdown, Modal } from 'antd'
import Icon, { IconsENUM } from '../UI/Icons/Icon'

const SUB_MENU_KEYS = {
  RATE: 'rate',
  SUBTITLES: 'subtitles',
  DOWNLOAD: 'download',
}

export const DotsMenu = (props) => {
  const { video, videoSchedule } = useVideoDataContext()
  const { playbackChangeHandler, subtitlesVisibilityHandler, playbackRate, isSubtitlesVisible } = usePlayerContext()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [subMenuOpenedKey, setSubmenuOpenedKey] = useState(null)

  const handleOpenSubmenu = (key) => {
    setSubmenuOpenedKey((prevState) => {
      if (key === prevState) return null
      else return key
    })
  }

  const menuItemsHandler = ({ key }) => {
    const [type, value] = key.split(',')
    if (type === SUB_MENU_KEYS.RATE) {
      playbackChangeHandler(value)
    } else if (type === SUB_MENU_KEYS.SUBTITLES) {
      subtitlesVisibilityHandler()
    } else if (type === SUB_MENU_KEYS.DOWNLOAD) {
      const download = () => {
        const link = document.createElement('a')
        link.href = video[value]
        link.click()
      }

      if (videoSchedule?.hasVideoInteractivity && value === 'url') {
        Modal.confirm({
          content:
            'It seems like you have interactive elements in your video, which will not work in the downloaded mp4 file. Please share your public video page, embed code, or download it as SCORM.',
          okText: 'Download anyway',
          okButtonProps: { type: 'default' },
          cancelButtonProps: { style: { display: 'none' } },
          onOk: download,
          closable: true,
          maskClosable: true,
        })
      } else {
        download()
      }
    }
    setIsMenuOpen(false)
  }

  const onOpenChangeMenu = (isOpen) => {
    setIsMenuOpen(isOpen)
    if (!isOpen) setSubmenuOpenedKey(null)
  }

  const menuItems = [
    ['vtt', 'srt'].includes(video?.subtitlesEnabled)
      ? {
          type: 'group',
          label: video?.url ? (
            <div
              className="item-group-submenu ant-dropdown-menu-item ant-dropdown-menu-title-content"
              onClick={() => handleOpenSubmenu(SUB_MENU_KEYS.DOWNLOAD)}
            >
              <Icon name={IconsENUM.download} /> Download
              <Icon
                name={subMenuOpenedKey === SUB_MENU_KEYS.DOWNLOAD ? IconsENUM.up_arrow : IconsENUM.down_arrow}
                className="expand-icon"
              />
            </div>
          ) : null,
          key: [SUB_MENU_KEYS.DOWNLOAD, false],
          children:
            subMenuOpenedKey === SUB_MENU_KEYS.DOWNLOAD
              ? [
                  {
                    key: [SUB_MENU_KEYS.DOWNLOAD, 'url'],
                    label: <div className="interactive-player submenu">Video</div>,
                  },
                  {
                    key: [SUB_MENU_KEYS.DOWNLOAD, 'subtitlesUrl'],
                    label: <div className="interactive-player submenu">Subtitles</div>,
                  },
                  {
                    type: 'divider',
                  },
                ]
              : null,
        }
      : {
          label: video?.url ? (
            <>
              <Icon name={IconsENUM.download} /> Download
            </>
          ) : null,
          key: [SUB_MENU_KEYS.DOWNLOAD, 'url'],
        },
    {
      label: (
        <div
          className="item-group-submenu ant-dropdown-menu-item ant-dropdown-menu-title-content"
          onClick={() => handleOpenSubmenu(SUB_MENU_KEYS.RATE)}
        >
          <Icon name="speed" />
          Playback speed
          <Icon
            name={subMenuOpenedKey === SUB_MENU_KEYS.RATE ? IconsENUM.up_arrow : IconsENUM.down_arrow}
            className="expand-icon"
          />
        </div>
      ),
      type: 'group',
      key: SUB_MENU_KEYS.RATE,
      children:
        subMenuOpenedKey === SUB_MENU_KEYS.RATE
          ? PLAY_RATES.map((rate) => ({
              key: [SUB_MENU_KEYS.RATE, rate],
              label: (
                <div className="interactive-player submenu">
                  <Icon
                    name="check"
                    className={rate === (playbackRate === 1 ? 'Normal' : playbackRate) ? 'selected' : 'normal'}
                  />
                  {rate}
                </div>
              ),
            }))
          : null,
    },
  ]

  // avoid intermediate values error and ghost option in menu
  if (video?.subtitlesEnabled === 'vtt') {
    const subtitlesOption = {
      label: (
        <>
          <Icon name={isSubtitlesVisible ? 'subtitles' : 'subtitles_off'} /> Subtitles{' '}
          {isSubtitlesVisible ? 'off' : 'on'}
        </>
      ),
      key: ['subtitles'],
    }

    menuItems.splice(1, 0, subtitlesOption)
  }
  return (
    <Dropdown
      open={isMenuOpen}
      placement="topRight"
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      overlayClassName="interactive-player-menu three-dot-menu"
      menu={{ items: menuItems, onClick: menuItemsHandler }}
      getPopupContainer={() => document.getElementById('menu-container')}
      onOpenChange={onOpenChangeMenu}
    >
      <Button aria-label="Player menu" shape="circle" type="text">
        <Icon name={IconsENUM.menu_dots_vertical} />
      </Button>
    </Dropdown>
  )
}
